function checkOverflow() {
  const swiper = this;
  const { isLocked: wasLocked, params } = swiper;
  const { slidesOffsetBefore } = params;

  if (slidesOffsetBefore) {
    const lastSlideIndex = slides.length - 1;
    const lastSlideRightEdge =
      slidesGrid[lastSlideIndex] +
      slidesSizesGrid[lastSlideIndex] +
      slidesOffsetBefore * 2;
    isLocked = size > lastSlideRightEdge;
  } else {
    isLocked = snapGrid.length === 1;
  }
  if (params.allowSlideNext === true) {
    allowSlideNext = !isLocked;
  }
  if (params.allowSlidePrev === true) {
    allowSlidePrev = !isLocked;
  }

  if (wasLocked && wasLocked !== isLocked) {
    isEnd = false;
  }
  if (wasLocked !== isLocked) {
    emit(isLocked ? 'lock' : 'unlock');
  }
}

export default { checkOverflow };
